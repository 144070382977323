import { useContext }      from "react";
import React, { useState } from "react";
import { useParams }       from "react-router-dom";
import { useWizard }       from "../../../hooks/useWizard";
import { FlowContext }     from "../../Flow/FlowContext";
import { Header }          from "../../Header";

const WaitingPage = () => {
  const [state, next] = useWizard();
  const context = useContext(FlowContext);
  const { id } = useParams();
  const [type, setType] = useState("");

  const handleClick = (type: string) => {
    setType(type);
    if (type === "game") {
      window.location.href = `/ar-games?productId=${id}&age=${state.age}&clinicName=${context.user.company}`;
    } else if (type === "video") {
      next("vr-videos");
    }
  };

  return (
    <main className=" w-content  items-center justify-items-center sm: max-sm:]">
      <Header title="Congrats 🏆"
              description={
                `Hi ${state.name}, you can now play an AR game or jump to video selection`
              }
              classNameDiv={"flex flex-col justify-items-center  w-279"}
      />

      <div className="  my-2 flex flex-col justify-center items-center w-fit	gap-2 flex-1 h-0
                       sm:pb-7">
        <div className="w-full">
          <img src="/images/arPng.png?v=20241021" alt="AR games"
               onClick={() => handleClick("game")}
               width={335}
               className={`h-full m-auto rounded-13 border-3 border-solid ${type === "game" ? "border-blue-primary " : "border-white"}`}/>
        </div>
        <div className="w-full">
          <img
            width={335}
            src="/images/videoSelect.png?v=20241021" alt="VR video" onClick={() => handleClick("video")}
            className={`h-full m-auto rounded-13  border-3 border-solid ${type === "video" ? "border-blue-primary" : "border-white"}`}/>
        </div>
      </div>
    </main>
  );
};

export default WaitingPage;